import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import {
  postApi,
  messages,
} from "../helpers/apiStructure";

// const initialState = {
//   // Dashboard
//   clientReviewList: [],
//   getBannersList:[],
// };
export const baseUrlWeb = "https://info.shyamg.in";
var baseUrl = "https://info.shyamg.in";

//website basic
export const addContact = createAsyncThunk("addContact", async (body) => {
    const result = await postApi(
      `${baseUrl}/api/contact/create`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  });
  //banners
export const getBanners = createAsyncThunk("getBanners", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/latest/banners`,
    "post",
    body
  );
  return result;
});
//visits
export const addIndustrialVisit = createAsyncThunk("addIndustrialVisit", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/industrialvisit/add`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
//review
export const clientReview = createAsyncThunk("clientReview", async (body) => {
  const result = await postApi(`${baseUrl}/api/review/list`, "post", body);
  return result;
});
//newslatter
export const newsletterAdd = createAsyncThunk("newsletterAdd", async (body) => {
  const result = await postApi(`${baseUrl}/api/newslatter/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//career opening
export const careerOpening = createAsyncThunk("careerOpening", async (body) => {
  const result = await postApi(`${baseUrl}/api/jobpost/list`, "get", body);
  return result;
});
//dealrship opening
export const dealrshipOpening = createAsyncThunk("dealrshipOpening", async (body) => {
  const result = await postApi(`${baseUrl}/api/dealership/list`, "post", body);
  return result;
});
//blog listing
export const getBlogs = createAsyncThunk("getBlogs", async (body) => {
  const result = await postApi(`${baseUrl}/api/blog/list`, "get", body);
  return result;
});
//single post
export const singlePost = createAsyncThunk("singlePost", async (body) => {
  const result = await postApi(`${baseUrl}/api/blog/${body}`, "get");
  return result;
});
  // export const updateComplaint = createAsyncThunk("updateComplaint", async (body) => {
  //   const result = await postApiFile(
  //     `${baseUrl}/api/complaint/edit/${body?.id}`,
  //     "put",
  //     body
  //   );
  //   await messages(result?.message, result?.status);
  //   return result;
  // });



const userReducer = createReducer(
  {},
  (builder) => {
    builder
      // demoooooo
      .addCase(clientReview.fulfilled, (state, action) => {
        state.clientReviewList = action.payload;
      })
      // Dashboard
      .addCase(getBanners.fulfilled, (state, action) => {
        state.getBannersList = action.payload;
      })
      // career Opening
      .addCase(careerOpening.fulfilled, (state, action) => {
        state.careerOpeningList = action.payload;
      })
      //getBlogs
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.getBlogList = action.payload?.list;
      })
      //single post
      .addCase(singlePost.fulfilled, (state, action) => {
        state.singlePostData = action.payload?.blog;
      })
      // dealrshipOpening Opening
      .addCase(dealrshipOpening.fulfilled, (state, action) => {
        state.dealrshipOpeningList = action.payload;
      })
  }
);

export default userReducer;
